import { autocomplete } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch/lite";

import "@algolia/autocomplete-theme-classic";

type AutocompleteItem = {
  label: string;
  url: string;
};

const appId = "IZ5F4DTIUD";
const apiKey = "6c719b8b37c21600c5f66d80229c28be";
const searchClient = algoliasearch(appId, apiKey);

autocomplete<AutocompleteItem>({
  container: "#autocomplete",
  placeholder: "ابحث عن الاطعمة المسموحة والممنوعة في الكيتو",
  openOnFocus: true,
  insights: true,
  translations: {
    detachedCancelButtonText: "الغاء",
  },
  debug: true,
  getSources() {
    return [
      {
        sourceId: "dev_ketopia",
        getItems({ query }) {
          return searchClient
            .initIndex("dev_ketopia")
            .search(query, {
              hitsPerPage: 8,
            })
            .then((results) => {
              return results.hits.map((hit) => ({
                label: hit["arabic-name"] || hit["aleflam-name"],
                url: `https://keto.sa/ar/is-it-keto/${hit["url-slug"]}`,
              }));
            });
        },
        getItemUrl({ item }) {
          return item.url;
        },
        templates: {
          item({ item }) {
            return item.label;
          },
        },
        onSelect({ item }) {
          window.location.href = item.url;
        },
      },
    ];
  },
});
